<template>
  <!-- ACTION HISTORY MODAL SIDEBAR -->

  <div
    style="
      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(32px);
      z-index: 9999;
    "
    :class="{ 'modal-visible': isVisible }"
    class="
      modal
      bottom-0
      pt-8
      px-8
      overflow-y-auto
      w-full
      text-black
      flex flex-col
      z-50
      h-full
      fixed
    "
  >
    <div class="relative">
      <div style="z-index: 9999" class="absolute top-0 right-0">
        <button
          @click.prevent="(files = [...selectedFiles]), $emit('overlayClicked')"
          class="border border-black top-0 right-0"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#000000"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </div>
    </div>

    <div class="flex z-50">
      <div v-if="!locked" class="w-1/3 leading-loose mt-2">
        <div
          class="
            sm:max-w-lg
            w-full
            p-10
            bg-white
            rounded-xl
            border border-gray-100
            shadow-xl
            z-10
          "
        >
          <div class="text-center">
            <h2 class="mt-5 text-3xl font-bold text-gray-900">File Upload</h2>
            <p class="mt-2 text-sm text-gray-400">
              Please upload your item image files below.
            </p>
          </div>
          <form class="mt-8 space-y-3" action="#" method="POST">
            <!-- <div class="grid grid-cols-1 space-y-2">
              <label class="text-sm font-bold text-gray-500 tracking-wide"
                >Title</label
              >
              <input
                class="
                  text-base
                  p-2
                  border border-gray-300
                  rounded-lg
                  focus:outline-none
                  focus:border-indigo-500
                "
                type=""
                placeholder="Rear View"
              />
            </div> -->
            <div class="grid grid-cols-1 space-y-2">
              <label class="text-sm font-bold text-gray-500 tracking-wide"
                >Attach Document</label
              >
              <div class="flex items-center justify-center w-full">
                <div
                  class="
                    flex flex-col
                    rounded-lg
                    border-4 border-dashed
                    w-full
                    h-60
                    p-10
                    group
                    text-center
                  "
                >
                  <div
                    @paste="onPaste"
                    class="
                      h-full
                      w-full
                      text-center
                      flex flex-col
                      items-center
                      justify-center
                      items-center
                    "
                  >
                    <div class="flex flex-auto max-h-48 w-2/5 mx-auto">
                      <svg
                        class="mx-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#b8b8b8"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <rect x="3" y="3" width="18" height="18" rx="2" />
                        <circle cx="8.5" cy="8.5" r="1.5" />
                        <path d="M20.4 14.5L16 10 4 20" />
                      </svg>
                    </div>
                    <p class="pointer-none text-gray-500">
                      Click and paste (Ctrl-v) your files here
                      <br />
                      or
                      <label
                        class="text-blue-600 cursor-pointer hover:underline"
                        >select a file
                        <input
                          multiple
                          @change="onFileSelect"
                          ref="fileInput"
                          type="file"
                          class="hidden"
                        />
                      </label>
                      from your computer
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p class="text-sm text-gray-300">
              <span>File types: PDF, DOCX, XLSX, XLS</span>
            </p>
            <div>
              <button
                v-if="!customerFiles"
                @click.prevent="onSelect"
                class="
                  my-5
                  w-full
                  flex
                  justify-center
                  bg-gray-500
                  text-gray-100
                  p-4
                  rounded-full
                  tracking-wide
                  font-semibold
                  focus:outline-none focus:shadow-outline
                  hover:bg-gray-600
                  shadow-lg
                  cursor-pointer
                  transition
                  ease-in
                  duration-300
                "
              >
                Save
              </button>
              <button
                v-if="customerFiles"
                @click.prevent="selectConfirmVisible = true"
                class="
                  my-5
                  w-full
                  flex
                  justify-center
                  bg-gray-500
                  text-gray-100
                  p-4
                  rounded-full
                  tracking-wide
                  font-semibold
                  focus:outline-none focus:shadow-outline
                  hover:bg-gray-600
                  shadow-lg
                  cursor-pointer
                  transition
                  ease-in
                  duration-300
                "
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>

      <div class="w-2/3 ml-4 py-4">
        <h1 class="font-bold text-xl">UPLOADED FILES</h1>

        <div>
          <div
            class="
              grid grid-cols-1
              md:grid-cols-2
              lg:grid-cols-4
              md:gap-x-2
              xl-grid-cols-2
              gap-y-2 gap-x-2
              mt-2
            "
          >
            <div
              class="
                container
                h-40
                mx-auto
                hover:shadow-lg
                rounded-lg
                max-w-md
                hover:shadow-2xl
                transition
                border-black border-2
                duration-300
                bg-gray-100
              "
              v-for="(file, i) in files"
              :key="i"
            >
              <div class="relative">
                <div class="absolute right-0 p-2">
                  <button v-if="!locked" @click="deleteFile(i)">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 24 24"
                      fill="white"
                      stroke="#ff0000"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="15" y1="9" x2="9" y2="15"></line>
                      <line x1="9" y1="9" x2="15" y2="15"></line>
                    </svg>
                  </button>
                </div>
              </div>
              <svg
                class="mx-auto mt-10 mb-2"
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"
                />
                <path d="M14 3v5h5M16 13H8M16 17H8M10 9H8" />
              </svg>

              <h1 class="font-bold text-sm text-center">
                {{ file.name }}
              </h1>
            </div>
            <div
              class="
                container
                h-40
                mx-auto
                hover:shadow-lg
                rounded-lg
                max-w-md
                hover:shadow-2xl
                transition
                border-black border-2
                duration-300
                bg-gray-100
              "
              v-for="(file, i) in documents"
              :key="i"
            >
              <!-- DELETE FILE CONFIRM MODAL -->
              <div
                v-if="deleteFileConfirmVisible"
                @click="deleteFileConfirmVisible = false"
                class="confirm-overlay"
              ></div>
              <div
                v-if="deleteFileConfirmVisible"
                class="delete-confirm text-xl shadow rounded p-10"
              >
                <div>
                  These documents will be deleted!
                  <span class="font-bold">Are you sure?</span>
                  <br />
                  <span class="font-bold text-red-500"
                    >This action cannot be undone</span
                  >
                </div>
                <div class="flex justify-end mt-6">
                  <button
                    @click="deleteFileConfirmVisible = false"
                    class="py-1 px-4 bg-green-600 text-white rounded mr-2"
                  >
                    No
                  </button>
                  <button
                    @click="
                      (deleteFileConfirmVisible = false), deleteExistingFile(i)
                    "
                    class="py-1 px-4 bg-red-600 text-white rounded"
                  >
                    Yes
                  </button>
                </div>
              </div>
              <!-- / DELETE FILE CONFIRM MODAL -->
              <div class="relative">
                <div class="absolute right-0 p-2">
                  <button
                    v-if="!locked"
                    @click="deleteFileConfirmVisible = true"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 24 24"
                      fill="white"
                      stroke="#ff0000"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="15" y1="9" x2="9" y2="15"></line>
                      <line x1="9" y1="9" x2="15" y2="15"></line>
                    </svg>
                  </button>
                </div>
              </div>
              <svg
                class="mx-auto mt-10 mb-2"
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"
                />
                <path d="M14 3v5h5M16 13H8M16 17H8M10 9H8" />
              </svg>

              <h1 class="font-bold text-sm text-center">
                <a target="_blank" :href="`${baseUrl}${file.file}`">{{
                  file.name
                }}</a>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- SELECT CONFIRM MODAL -->
    <div
      v-if="selectConfirmVisible"
      @click="selectConfirmVisible = false"
      class="confirm-overlay"
    ></div>
    <div
      v-if="selectConfirmVisible"
      class="delete-confirm text-xl shadow rounded p-10"
    >
      <div>
        These documents will be visible to customers!
        <span class="font-bold">Are you sure?</span>
        <br />
      </div>
      <div class="flex justify-end mt-6">
        <button
          @click="selectConfirmVisible = false"
          class="py-1 px-4 bg-green-600 text-white rounded mr-2"
        >
          No
        </button>
        <button
          @click="(selectConfirmVisible = false), onSelect()"
          class="py-1 px-4 bg-red-600 text-white rounded"
        >
          Yes
        </button>
      </div>
    </div>
    <!-- / SELECT CONFIRM MODAL -->
  </div>
  <!-- INQUIRY MODAL SIDEBAR -->
</template>

<script>
import alerts from "@/utils/alert-types";

export default {
  name: "FileUploadModal",
  props: ["isVisible", "customerFiles", "documents", "selectedFiles", "locked"],
  emits: ["overlayClicked", "selected"],
  data() {
    return {
      files: [],
      deleted: [],
      baseUrl: `${process.env.VUE_APP_API_HOST}/uploads/`,
      deleteFileConfirmVisible: false,
      selectConfirmVisible: false,
    };
  },
  methods: {
    deleteFile(i) {
      this.files = this.files.filter((f, ind) => ind != i);
    },
    deleteExistingFile(i) {
      this.deleted.push(this.documents[i]);
      this.$emit("selected", {
        files: this.files,
        deletedFiles: this.deleted,
      });
      this.deleted = [];
    },
    onSelect() {
      this.$emit("selected", {
        files: this.files,
        deletedFiles: this.deleted,
      });
      this.$emit("overlayClicked");
      this.files = [];
    },
    onPaste(event) {
      if (this.isVisible) {
        event.preventDefault();

        if (this.locked) {
          this.$store.dispatch("addAlert", {
            ...alerts.danger,
            alert: "the item is locked",
          });
          return;
        }

        const fileList = event.clipboardData.files;

        const files = [];

        if (fileList.length) {
          for (const key in fileList) {
            if (typeof fileList[key] == "object") {
              if (fileList[key].type) {
                console.log(fileList[key].type);
                const allowed =
                  fileList[key].type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                  fileList[key].type === "application/pdf" ||
                  fileList[key].type ===
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                  fileList[key].type === "application/vnd.ms-excel";

                if (allowed) {
                  files.push(fileList[key]);
                } else {
                  this.$store.dispatch("addAlert", {
                    ...alerts.danger,
                    alert: "one of the files you selected is not allowed!",
                  });
                }
              }
            }
          }
        }

        this.files.push(...files);
      }
    },
    onFileSelect(e) {
      if (this.locked) {
        this.$store.dispatch("addAlert", {
          ...alerts.danger,
          alert: "the item is locked",
        });
        this.$refs.fileInput.value = null;
        return;
      }
      let files = [];

      if (e.target.files.length) {
        const fileList = e.target.files;

        for (const key in fileList) {
          if (typeof fileList[key] == "object") {
            if (fileList[key].type) {
              const allowed =
                fileList[key].type ===
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                fileList[key].type === "application/pdf" ||
                fileList[key].type ===
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                fileList[key].type === "application/vnd.ms-excel";

              if (allowed) {
                files.push(fileList[key]);
              } else {
                this.$store.dispatch("addAlert", {
                  ...alerts.danger,
                  alert: "one of the files you selected is not allowed!",
                });
              }
            }
          }
        }
        this.files.push(...files);
      }

      this.$refs.fileInput.value = null;
    },
  },
};
</script>

 

<style scoped>
.delete-confirm {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
}

.confirm-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9998;
}

.modal-background-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 997;
}

.modal-background-overlay2 {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1001;
}

.modal-background-overlay3 {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 49;
  transition: background 0.2s 0.3s, visibility 1s;
}

.modal-background-overlay--hidden {
  visibility: hidden;
}

.modal-background-overlay--black {
  background: rgba(0, 0, 0, 0.8);
  visibility: visible;
}

.modal {
  transition: transform 0.5s;
  right: 0;
  transform: translatex(100%);
}

.modal-visible {
  transform: translatex(0%);
}

.hasImage:hover section {
  background-color: rgba(5, 5, 5, 0.4);
}
.hasImage:hover button:hover {
  background: rgba(5, 5, 5, 0.45);
}

#overlay p,
i {
  opacity: 0;
}

#overlay.draggedover {
  background-color: rgba(255, 255, 255, 0.7);
}
#overlay.draggedover p,
#overlay.draggedover i {
  opacity: 1;
}

.group:hover .group-hover\:text-blue-800 {
  color: #2b6cb0;
}
</style>

